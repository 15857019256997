import React, { useState } from 'react';

import styles from './styles.scss';
import Asterisk from './Asterisk';

export default function CheckBox({ label, meta, input, name, fieldValue, sup }) {
    const [checked, setChecked] = useState(fieldValue);

    const handleCheck = () => {
        setChecked(!checked);
    };

    return (
        <div className={styles.checkBox}>
            <div className={styles.mainComponents}>
                <input
                    {...input}
                    type="checkbox"
                    name={name}
                    id={name}
                    value={fieldValue}
                    checked={checked}
                    onClick={handleCheck}
                    style={{ marginTop: '8px' }}
                />
                <div style={{ paddingLeft: '10px', marginBottom: '1vh' }}>
                    {label}
                    {' '}
                    {!!sup && <Asterisk />}
                </div>
            </div>
            {meta.error && meta.touched && <span style={{ color: 'red' }}>{meta.error}</span>}
        </div>
    );
}
