import React from 'react';
import PropTypes from 'prop-types';

const Grid = ({ children, className }) => <div className={className}>{children}</div>;

Grid.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

Grid.defaultProps = {
    children: '',
    className: 'grid-md-6',
};

export default Grid;
