import { useState, useEffect } from 'react';

const useOutsideClick = (ref, type = '') => {
    const [toggle, setToggle] = useState(false);

    function onOutsideClick(e) {
        if (!ref.current.contains(e.target)) {
            setToggle(false);
        }
    }

    useEffect(() => {
        if (toggle) {
            window.addEventListener('click', onOutsideClick);
        } else {
            window.removeEventListener('click', onOutsideClick);
        }
        return () => {
            window.removeEventListener('click', onOutsideClick);
        };
    }, [toggle]);

    const handleToggle = () => {
        if (type === 'multiselect') return setToggle(true);
        return setToggle(prevState => !prevState);
    };

    return [toggle, handleToggle];
};

export default useOutsideClick;
