/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import { _cs } from '@togglecorp/fujs';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';

import styles from '../styles.scss';
import { Input, Row, Grid, Select, CheckBox } from '#components/FormComponents';
import {
    reportIncident,
    linkCitizenToIncident,
    patchIncident,
    reportIncidentLoss,
    setIncidentTitle,
    getEventList,
} from '../../services';
import { returnIdFromTitle } from '#utils/common';
import { addIncidentActionIP } from '#actionCreators';

const required = value => (value ? undefined : 'This field is required!');
const sourceArray = [{ id: '1', title: 'Citizen Report' }];

const CreateIncident = (props) => {
    const { className, incident, setLinked, closeModal, incidentTypes, setIncidentId } = props;
    const [eventList, setEventList] = useState([]);
    const { wards } = useSelector(state => state.page);
    const [initialValues, setInitialValues] = useState({});
    const firstFormRef = useRef(null);
    const errorCheckBtnRef = useRef(null);
    const dispatch = useDispatch();

    if (incidentTypes) {
        // eslint-disable-next-line no-nested-ternary
        incidentTypes.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
    }

    useEffect(() => {
        getEventList().then((res) => {
            setEventList(res.data.results);
        });
    }, []);

    const onSubmit = (values) => {
        const submissionValues = {
            ...values,
            point: JSON.stringify(incident.point),
            incidentOn: incident.createdOn,
            event: returnIdFromTitle(eventList, values.event),
            hazard: returnIdFromTitle(incidentTypes, values.hazard),
            source: 'citizen_report',
            wards: incident.ward,
            approved: true,
            description: incident.description,
        };
        reportIncident(submissionValues)
            .then((res) => {
                linkCitizenToIncident(incident.id, res.data.id)
                    .then((result) => {
                        setLinked(true);
                        setIncidentId(res.data.id);
                    })
                    .catch(err => console.log('eroor occured while linking', err));

                setIncidentTitle(res.data.title, res.data.id).catch(err => console.log('error setting incident title'));

                const lossValues = {
                    description: 'N/A',
                    incident: res.data.id,
                    estimatedLoss: 0,
                };

                reportIncidentLoss(lossValues)
                    .then((result) => {
                        const patchValue = {
                            title: res.data.title,
                            loss: result.data.id,
                        };
                        patchIncident(patchValue, res.data.id)
                            .then((patchResult) => {
                                const wardValue = wards.filter(
                                    ward => ward.id === patchResult.data.wards[0],
                                );
                                const finalValue = { ...patchResult.data, wards: wardValue };
                                const incidentObject = { incident: finalValue };
                                dispatch(addIncidentActionIP(incidentObject));
                            })
                            .catch(err => console.log('error in patch', err));
                    })
                    .catch(err => console.log('error posting loss', err));
            })
            .catch(err => console.log('eroor occured while creating incident', err));
    };

    const handleErrorCheck = (errors) => {
        firstFormRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
    };

    return (
        <div className={_cs(styles.about, className)}>
            <div className={styles.detailSection}>
                <Form
                    onSubmit={onSubmit}
                    validate={(values) => {
                        const errors = {};
                        return errors;
                    }}
                    initialValues={{ ...initialValues }}
                    render={({ handleSubmit, values, errors }) => (
                        <form onSubmit={handleSubmit} ref={firstFormRef}>
                            <Row>
                                <Grid>
                                    <Field
                                        name="description"
                                        text={values.description}
                                        component={Input}
                                        label="Description"
                                        validate={required}
                                        fieldName="description"
                                        sup="*"
                                    />
                                </Grid>
                            </Row>
                            <Row>
                                <Grid>
                                    <Field
                                        name="cause"
                                        text={values.cause}
                                        component={Input}
                                        label="Cause"
                                        validate={required}
                                        fieldName="cause"
                                        valueForm={values.cause}
                                        sup="*"
                                    />
                                </Grid>
                            </Row>
                            <Row>
                                <Grid>
                                    <Field
                                        name="streetAddress"
                                        text={values.streetAddress}
                                        component={Input}
                                        label="Address"
                                        validate={required}
                                        fieldName="streetAddress"
                                        sup="*"
                                    />
                                </Grid>
                            </Row>
                            <Row>
                                <Grid>
                                    <Field
                                        name="event"
                                        label="Event"
                                        sup="*"
                                        component={Select}
                                        options={eventList}
                                        selectedItem={values.event}
                                        validate={required}
                                        fieldName="event"
                                    />
                                </Grid>
                            </Row>
                            <Row>
                                <Grid>
                                    <Field
                                        name="hazard"
                                        label="Hazard"
                                        component={Select}
                                        options={incidentTypes}
                                        selectedItem={values.hazard}
                                        validate={required}
                                        sup="*"
                                    />
                                </Grid>
                            </Row>
                            <Row>
                                <Grid>
                                    <Field
                                        name="source"
                                        label="Source"
                                        component={Select}
                                        options={sourceArray}
                                        selectedItem={values.source}
                                        validate={required}
                                        fieldName="source"
                                        sup="*"
                                    />
                                </Grid>
                            </Row>
                            <Row>
                                <Grid>
                                    <Field
                                        name="verificationMessage"
                                        text={values.verificationMessage}
                                        component={Input}
                                        label="Verification Message"
                                        fieldName="verificationMessage"
                                    />
                                </Grid>
                            </Row>
                            <Row>
                                <Grid>
                                    <Field
                                        name="verified"
                                        text={values.verified}
                                        component={CheckBox}
                                        label="Verified"
                                        validate={required}
                                        sup="*"
                                    />
                                </Grid>
                            </Row>
                            <button
                                type="button"
                                style={{ display: 'none' }}
                                onClick={() => handleErrorCheck(errors)}
                                ref={errorCheckBtnRef}
                            />
                        </form>
                    )}
                />
                <div className={styles.buttonSection}>
                    <div
                        className={styles.button}
                        onClick={() => {
                            errorCheckBtnRef.current.click();
                        }}
                    >
                        Create Incident
                    </div>
                    <div className={styles.button} onClick={closeModal}>
                        Discard
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateIncident;
