/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import Asterisk from './Asterisk';

import styles from './styles.scss';

export default function Input({ placeholder, label, type, meta, input, disabled, fieldName, sup }) {
    const hanleChange = (e) => {
        const { value, name } = e.target;
        input.onChange(value);
    };

    return (
        <div className={styles.textInput}>
            <label>
                {label}
                {!!sup && <Asterisk />}
            </label>
            <input
                type={type}
                placeholder={placeholder}
                {...input}
                value={input.value}
                className={styles.customInput}
                onChange={(e) => {
                    hanleChange(e);
                }}
                name={fieldName}
                disabled={disabled}
            />
            {meta.error && meta.touched && <span style={{ color: 'red' }}>{meta.error}</span>}
        </div>
    );
}

Input.propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    fieldName: PropTypes.string,
    sup: PropTypes.string,
};

Input.defaultProps = {
    placeholder: '',
    label: '',
    type: 'text',
    disabled: false,
    text: '',
    fieldName: '',
    sup: '',
};
