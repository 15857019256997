import AllActionTypes from './types.ts';

const initialState = {
    notificationId: '',
    vehicleLists: '',
    dispatchId: undefined,
};
const curtain = (state = initialState, action) => {
    switch (action.type) {
        case AllActionTypes.SET_NOTIFICATION_ID:
            return {
                ...state,
                notificationId: action.payload,
            };
        case AllActionTypes.REMOVE_NOTIFICATION_ID:
            return {
                ...state,
                notificationId: '',
            };
        case AllActionTypes.GET_VEHICLE_LIST_SUCCESS:
            return {
                ...state,
                vehicleLists: action.payload,
            };
        case AllActionTypes.SET_INCIDENT_FORM_VALUES:
        default:
            return {
                ...state,
            };
    }
};

export default curtain;
