/* eslint-disable */
import axios from 'axios';
import { camelCaseToSnake } from '#utils/common';

const baseUrl = process.env.REACT_APP_API_SERVER_URL;

export const getCitizenReports = () => {
    return axios({
        method: 'GET',
        url: `${baseUrl}/citizen-report/?ordering=-created_on`,
    });
};
