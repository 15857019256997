import axios from 'axios';
import { camelCaseToSnake } from '#utils/common';

const baseUrl = process.env.REACT_APP_API_SERVER_URL;

export const prepareFormData = (data) => {
    const formData = Object.entries(data).reduce(
        (obj, item) => ({ ...obj, [camelCaseToSnake(item[0])]: item[1] }),
        {},
    );
    const formDataObj = new FormData();
    Object.keys(formData).forEach((key) => {
        formDataObj.append(key, formData[key]);
    });
    return formDataObj;
};

export const getCitizenReport = notificationId => axios({
    method: 'GET',
    url: `${baseUrl}/citizen-report/${notificationId}/`,
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});

export const getNearbyIncidents = notificationId => axios({
    method: 'GET',
    url: `${baseUrl}/admin-nearby-incident/${notificationId}/`,
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});

export const getReportImages = (id) => {
    const data = new FormData();
    data.append('cr_id', id);
    return axios({
        method: 'POST',
        url: `${baseUrl}/media-cr/`,
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const reportIncident = (values) => {
    const data = prepareFormData(values);

    return axios({
        method: 'POST',
        url: `${baseUrl}/incident/`,
        data,
    });
};

export const patchIncident = (values, id) => {
    const data = prepareFormData(values);

    return axios({
        method: 'PATCH',
        url: `${baseUrl}/incident/${id}/`,
        data,
    });
};

export const setIncidentTitle = (title, id) => axios({
    method: 'GET',
    url: `${baseUrl}/incident-title/?id=${id}&&title=${title}`,
});

export const linkCitizenToIncident = (crId, inId) => {
    const data = new FormData();
    data.append('incident_id', inId);
    return axios({
        method: 'POST',
        url: `${baseUrl}/link-incident/${crId}/`,
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const reportIncidentLoss = (values) => {
    const data = prepareFormData(values);

    return axios({
        method: 'POST',
        url: `${baseUrl}/loss/`,
        data,
    });
};

export const getEventList = () => axios({
    method: 'GET',
    url: `${baseUrl}/event/`,
});
