/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import { _cs } from '@togglecorp/fujs';
import { useSelector, useDispatch } from 'react-redux';

import Modal from '#rscv/Modal';
import ModalHeader from '#rscv/Modal/Header';
import ModalBody from '#rscv/Modal/Body';
import DangerButton from '#rsca/Button/DangerButton';
import ReportSection from './ReportSection';
import Map from './MapSection';
import styles from './styles.scss';
import { removeNotificationId } from '#store/atom/incident/actions';
import { getCitizenReport, getNearbyIncidents, getReportImages } from './services';

export default function IncidentModal() {
    const { notificationId } = useSelector(state => state.incident);
    const [mainData, setMainData] = useState('');
    const [nearbyIncidents, setNearbyIncidents] = useState('');
    const [imageList, setImageList] = useState('');
    const dispatch = useDispatch();
    const incidentTypesObjcts = useSelector(state => state.page.hazardTypes);
    const incidentTypes = Object.keys(incidentTypesObjcts).map(key => incidentTypesObjcts[key]);

    const closeModal = () => {
        dispatch(removeNotificationId());
    };

    useEffect(() => {
        if (notificationId) {
            getCitizenReport(notificationId).then(res => setMainData(res.data));
            getNearbyIncidents(notificationId).then((res) => {
                const tempArray = [];
                res.data.forEach((data, index) => {
                    const date = data.createdOn.slice(0, 10);
                    const time = data.createdOn.slice(11, 19);
                    const serial = index + 1;
                    tempArray.push({ ...data, date, time, serial });
                });
                setNearbyIncidents(tempArray);
            });
            getReportImages(notificationId).then(res => setImageList(res.data));
        }
    }, [notificationId]);

    return (
        <Modal className={_cs(styles.loginModal)}>
            <ModalHeader
                className={styles.header}
                title="BIPAD"
                rightComponent={
                    <DangerButton
                        transparent
                        iconName="close"
                        onClick={closeModal}
                        title="Close Modal"
                    />
                }
            />
            <ModalBody className={styles.content}>
                <div className={styles.bodySection}>
                    <ReportSection
                        mainData={mainData}
                        nearbyIncidents={nearbyIncidents}
                        imageList={imageList}
                        closeModal={closeModal}
                        incidentTypes={incidentTypes}
                    />
                </div>
                <div className={styles.bodySection}>
                    <Map
                        mainData={mainData}
                        nearbyIncidents={nearbyIncidents}
                        incidentTypes={incidentTypes}
                    />
                </div>
            </ModalBody>
        </Modal>
    );
}
