import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import { isDefined, unique } from '@togglecorp/fujs';

import MapSource from '#re-map/MapSource';
import MapLayer from '#re-map/MapSource/MapLayer';
import MapState from '#re-map/MapSource/MapState';
import MapTooltip from '#re-map/MapTooltip';
import { getParams } from '#components/Cloak';
import TruckIcon from '#resources/icons/Truck-icon.svg';
import RedTruckIcon from '#resources/icons/Truck-icon.png';
import AmbulanceIcon from '#resources/icons/Ambulance.svg';
import AddResourceForm from '#views/RiskInfo/LeftPane/Details/CapacityAndResources/AddResourceForm';

// import SVGMapIcon from '#components/SVGMapIcon';
import CommonMap from '#components/CommonMap';
import ProvinceMap from '#components/ProvinceMap';
import {
    hazardTypesSelector,
    provincesMapSelector,
    districtsMapSelector,
    municipalitiesMapSelector,
    wardsMapSelector,
    userSelector,
} from '#selectors';

import {
    setIncidentActionIP,
    patchIncidentActionIP,
    removeIncidentActionIP,
    setVehicleListActionIP,
} from '#actionCreators';

import { mapStyles } from '#constants';
import IncidentInfo from '#components/IncidentInfo';
import {
    createRequestClient,
    methods,
} from '#request';
import {
    getYesterday,
    framize,
    getImage,
} from '#utils/common';

import {
    incidentPointToGeojson,
    incidentPolygonToGeojson,
    singleDispatchedPointToGeojson,
    vehiclePointToGeojson,
} from '#utils/domain';

import AddIncidentForm from '../LeftPane/AddIncidentForm';

import styles from './styles.scss';
import MapImage from '#re-map/MapImage';
import VehicleInfo from '#components/VehicleInfo';
import SVGMapIcon from '#components/SVGMapIcon';
import { getHashFromBrowser } from '#rscg/HashManager';
import InventoriesModal from '#views/RiskInfo/LeftPane/Details/CapacityAndResources/InventoriesModal';
// import InventoriesModal from '../LeftPane/InventoriesModal';


const propTypes = {
    incidentList: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    vehicleList: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    hazards: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    recentDay: PropTypes.number.isRequired, // eslint-disable-line react/forbid-prop-types
    onIncidentHover: PropTypes.func,
    onVehicleHover: PropTypes.func,
    mapHoverAttributes: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    // eslint-disable-next-line react/forbid-prop-types
    mapVehicleHoverAttributes: PropTypes.array.isRequired,
    wardsMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    provincesMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    districtsMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    municipalitiesMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    setIncident: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    removeIncident: PropTypes.func.isRequired,
    patchIncident: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
    requests: PropTypes.object.isRequired,
    sourceKey: PropTypes.string,
    isProviceOnlyMap: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
    user: PropTypes.object,
};

const defaultProps = {
    sourceKey: 'incidents',
    isProviceOnlyMap: false,
    user: undefined,
    onIncidentHover: undefined,
    onVehicleHover: undefined,
};
const vehiclesIcons = [
    { name: 'fireengine', icon: TruckIcon, color: 'gray' },
    { name: 'ambulance', icon: AmbulanceIcon, color: 'gray' },
];
// const vehiclesIcons = [
//     { name: 'fireengine_available', icon: TruckIcon, color: '#4CAF50' },
//     { name: 'fireengine_not_available', icon: TruckIcon, color: '#FF5722' },
//     { name: 'fireengine_idle', icon: TruckIcon, color: 'gray' },
//     { name: 'fireengine_deployed', icon: TruckIcon, color: '#2196F3' },
//     { name: 'fireengine_rejected', icon: TruckIcon, color: '#F44336' },
//     { name: 'fireengine_completed', icon: TruckIcon, color: '#8BC34A' },
//     { name: 'fireengine_assigned', icon: TruckIcon, color: '#FFC107' },
//     { name: 'ambulance_available', icon: AmbulanceIcon, color: '#4CAF50' },
//     { name: 'ambulance_not_available', icon: AmbulanceIcon, color: '#FF5722' },
//     { name: 'ambulance_idle', icon: AmbulanceIcon, color: 'gray' },
//     { name: 'ambulance_deployed', icon: AmbulanceIcon, color: '#2196F3' },
//     { name: 'ambulance_rejected', icon: AmbulanceIcon, color: '#F44336' },
//     { name: 'ambulance_completed', icon: AmbulanceIcon, color: '#8BC34A' },
//     { name: 'ambulance_assigned', icon: AmbulanceIcon, color: '#FFC107' },
// ];
const mapStateToProps = state => ({
    hazards: hazardTypesSelector(state),
    provincesMap: provincesMapSelector(state),
    districtsMap: districtsMapSelector(state),
    municipalitiesMap: municipalitiesMapSelector(state),
    wardsMap: wardsMapSelector(state),
    user: userSelector(state),
});

const visibleLayout = {
    visibility: 'visible',
};
const noneLayout = {
    visibility: 'none',
};

const mapDispatchToProps = dispatch => ({
    patchIncident: params => dispatch(patchIncidentActionIP(params)),
    setIncident: params => dispatch(setIncidentActionIP(params)),
    removeIncident: params => dispatch(removeIncidentActionIP(params)),
    setVehicleList: params => dispatch(setVehicleListActionIP(params)),

});

const requestOptions = {
    incidentDeleteRequest: {
        url: ({ params: { incidentId } }) => `/incident/${incidentId}/`,
        method: methods.DELETE,
        onSuccess: ({
            props: { removeIncident },
            params: {
                incidentId,
                onIncidentRemove,
            },
        }) => {
            removeIncident({ incidentId });
            onIncidentRemove();
        },
    },
    vehicleListGetRequest: {
        url: '/fire-engine/',
        method: methods.GET,
        onSuccess: ({ response, props: { setVehicleList } }) => {
            const { results: vehicleList = [] } = response;
            setVehicleList({ vehicleList });
        },
    },
};

class IncidentMap extends React.PureComponent {
    static propTypes = propTypes;

    static defaultProps = defaultProps;


    constructor(props) {
        super(props);
        this.prevTimestamp = undefined;
        this.state = {
            markerData: {},
        };
        // this.socket = io.connect('http://localhost:3000');
    }

    componentDidMount() {
        // this.socket.on('marker', (data) => {
        //     testMarkers = data;
        //     this.setState({ markerData: data });
        // });
    }

    componentWillUnmount() {
        // this.socket.off('marker');
    }

    getUserParams = memoize(getParams);

    getPointFeatureCollection = memoize(incidentPointToGeojson)

    getPointFeatureCollectionVehicle = memoize(vehiclePointToGeojson)

    getPointFeatureCollectionVehicleDispatchSingle = memoize(singleDispatchedPointToGeojson)

    getPolygonFeatureCollection = memoize(incidentPolygonToGeojson);

    getFilter = memoize(timestamp => (
        ['>', ['get', 'incidentOn'], timestamp]
    ))

    handleAnimationKeyframe = framize((percent) => {
        const p = percent;
        const radius = p * 20;
        const opacity = (1 - p);
        return {
            'circle-radius': radius,
            // 'circle-radius': ['+', mapStyles.incidentPoint.fill['circle-radius'], radius],
            'circle-opacity': opacity,
        };
    })

    // handleAnimationKeyframeRealtime = framize((percent) => {
    //     map.getSource('point').setData(point);

    //     percent;
    // });

    handleIncidentMouseEnter = (feature) => {
        const {
            onIncidentHover,
        } = this.props;

        if (onIncidentHover) {
            onIncidentHover(feature.id);
        }
    }

    handleVehicleMouseEnter = (feature) => {
        const {
            onVehicleHover,
        } = this.props;

        if (onVehicleHover) {
            onVehicleHover(feature.id);
        }
    }

    handleIncidentMouseLeave = () => {
        const {
            onIncidentHover,
        } = this.props;

        if (onIncidentHover) {
            onIncidentHover(undefined);
        }
    }

    handleVehicleMouseLeave = () => {
        const {
            onVehicleHover,
        } = this.props;

        if (onVehicleHover) {
            onVehicleHover(undefined);
        }
    }

    handleIncidentClick = (feature, lngLat) => {
        const { id } = feature;
        const { incidentList } = this.props;
        const incident = incidentList.find(item => item.id === id);
        this.setState({
            incident,
            incidentLngLat: lngLat,
        });
    }

    handleVehicleClick = (feature, lngLat) => {
        const { id } = feature;
        const { vehicleList } = this.props;

        const vehicle = vehicleList.find(item => item.id === id);

        this.setState({
            vehicle,
            vehicleLngLat: lngLat,
        });
    }

    handleAmbulanceClick = (feature, lngLat) => {
        const { id } = feature;
        const { ambulanceList } = this.props;
        const ambulance = ambulanceList.find(item => item.id === id);
        this.setState({
            ambulance,
            ambulanceLngLat: lngLat,
        });
    }

    handleIncidentClose = () => {
        this.setState({
            incident: undefined,
            incidentLngLat: undefined,
        });
    }

    handleVehicleClose = () => {
        this.setState({
            vehicle: undefined,
            vehicleLngLat: undefined,
        });
    }

    handleAmbulanceClose = () => {
        this.setState({
            ambulance: undefined,
            ambulanceLngLat: undefined,
        });
    }

    handleEditIncidentClick = () => {
        this.setState({ showEditIncidentModal: true });
    }

    handleCloseEditModal = () => {
        this.setState({ showEditIncidentModal: false });
    }

    handleIncidentEdit = (incident) => {
        const { setIncident } = this.props;

        if (isDefined(incident)) {
            setIncident({ incident });
        }
    }

    handleResourceEdit = (/* newEvent */) => {
        // handleResourceEdit = (resourceId, resource) => {
        const { requests: { vehicleListGetRequest } } = this.props;
        vehicleListGetRequest.do();
    }

    handleLossEdit = (loss, incident) => {
        const { patchIncident } = this.props;

        patchIncident({
            incident: {
                loss,
            },
            incidentId: incident.id,
        });
    }

    handleIncidentDelete = () => {
        const { incident: { id: incidentId } } = this.state;
        const { requests: { incidentDeleteRequest } } = this.props;

        incidentDeleteRequest.do({
            incidentId,
            onIncidentRemove: this.handleIncidentClose,
        });
    }

    handleEditClick = () => {
        this.setState({
            showVehicleForm: true,
            resourceLngLat: undefined,
        });
    }

    handleEditClickAmbulance = () => {
        this.setState({
            showAmbulanceForm: true,
            resourceLngLat: undefined,
        });
    }

    handleEditResourceFormCloseButtonClick = () => {
        this.setState({
            showVehicleForm: false,
            showAmbulanceForm: false,
            // resourceLngLat: undefined,
        });
    };

    handleInventoryModalClose = () => {
        this.setState({
            showInventoryModal: false,
        });
    }

    handleShowInventoryClick = () => {
        this.setState({
            showInventoryModal: true,
            resourceLngLat: undefined,
        });
    }

    mapImageRendererParams = (_, hazard) => {
        const image = getImage(hazard.icon)
            .setAttribute('crossOrigin', '');

        return ({ name: hazard.title, image });
    }

    render() {
        const {
            incidentList,
            vehicleList,
            ambulanceList,
            singleDispatchedVehicle,
            hazards,
            recentDay,
            mapHoverAttributes,
            mapVehicleHoverAttributes,
            wardsMap,
            provincesMap,
            districtsMap,
            municipalitiesMap,
            user,
            isHovered,
            isVehicleHovered,
            requests: {
                incidentDeleteRequest: {
                    pending: incidentDeletePending,
                },
            },
            sourceKey,
            isProviceOnlyMap,
        } = this.props;

        const icons = unique(
            incidentList
                .filter(incident => incident.hazardInfo && incident.hazardInfo.icon)
                .map(incident => incident.hazardInfo.icon),
            icon => icon,
        );
        const pointFeatureCollectionVehicle = this.getPointFeatureCollectionVehicle(vehicleList);
        // eslint-disable-next-line max-len
        const pointFeatureCollectionAmbulance = this.getPointFeatureCollectionVehicle(ambulanceList);
        const pointFeatureCollection = this.getPointFeatureCollection(incidentList, hazards);
        const polygonFeatureCollection = this.getPolygonFeatureCollection(incidentList, hazards);
        const recentTimestamp = getYesterday(recentDay);
        const filter = this.getFilter(recentTimestamp);
        // eslint-disable-next-line max-len
        const dispatchedVehicleFeatureColl = singleDispatchedVehicle && this.getPointFeatureCollectionVehicleDispatchSingle(singleDispatchedVehicle);
        const {
            incident,
            incidentLngLat,
            vehicle,
            vehicleLngLat,
            ambulance,
            ambulanceLngLat,
            showEditIncidentModal,
            showInventoryModal,
            markerData,
            showVehicleForm,
            showAmbulanceForm,

        } = this.state;

        const tooltipOptions = {
            closeOnClick: true,
            closeButton: false,
            offset: 8,
        };
        // console.log(vehicleList, 'vehicleList');
        // console.log(pointFeatureCollectionVehicle, 'pointFeatureCollectionVehicle');
        // console.log(pointFeatureCollectionAmbulance, 'pointFeatureCollectionAmbulance');
        const lossServerId = incident && incident.loss && incident.loss.id;
        const incidentServerId = incident && incident.id;
        const Map = isProviceOnlyMap ? ProvinceMap : CommonMap;
        const params = this.getUserParams(user);
        const showEditIncident = !!(params && params.change_incident);
        const showDeleteIncident = !!(params && params.delete_incident);
        return (
            <React.Fragment>
                <Map sourceKey={sourceKey} />
                {vehiclesIcons.map(fireEng => (
                    <SVGMapIcon
                        key={fireEng.name}
                        src={fireEng.icon}
                        name={fireEng.name}
                        fillColor={fireEng.color}
                    />
                ))}
                <MapImage
                    url={RedTruckIcon}
                    name="truck"
                />
                <MapSource
                    sourceKey="incident-polygons"
                    sourceOptions={{ type: 'geojson' }}
                    geoJson={polygonFeatureCollection}
                >
                    <MapLayer
                        layerKey="incident-polygon-fill"
                        layerOptions={{
                            type: 'fill',
                            paint: mapStyles.incidentPolygon.fill,
                        }}
                    />
                </MapSource>


                <MapSource
                    sourceKey="vehicle-points"
                    sourceOptions={{ type: 'geojson' }}
                    geoJson={pointFeatureCollectionVehicle}
                >
                    <MapLayer
                        layerKey="vehicle-points-fill"
                        layerOptions={{
                            type: 'circle',
                            paint: isVehicleHovered
                                ? mapStyles.vehiclePoint.dimFill
                                : mapStyles.vehiclePoint.fill,
                        }}
                        onClick={this.handleVehicleClick}
                        onMouseEnter={this.handleVehicleMouseEnter}
                        onMouseLeave={this.handleVehicleMouseLeave}
                    />
                    {/* <MapLayer
                        layerKey="-vehicle-symbol-icon-fire-bridage"
                        layerOptions={{
                            type: 'symbol',
                            // filter: ['!', ['has', 'point_count']],
                            layout: {
                                'icon-image': 'truck',
                                'icon-size': 0.016,
                            },
                        }}
                    /> */}
                    <MapLayer
                        layerKey="-vehicle-symbol-icon-fire"
                        layerOptions={{
                            type: 'symbol',
                            paint: isVehicleHovered ? mapStyles.vehiclePoint.symbolFill
                                : mapStyles.vehiclePoint.symbolDim,
                            layout: {
                                // 'icon-image': ['get', 'iconStatus'],
                                'icon-image': 'fireengine',
                                'icon-size': 0.21,
                                // 'icon-opacity': isVehicleHovered ? 1 : 0,
                            },
                        }}
                    />

                    { vehicleLngLat && (

                        <MapTooltip
                            coordinates={vehicleLngLat}
                            tooltipOptions={tooltipOptions}
                            onHide={this.handleVehicleClose}
                        >
                            <VehicleInfo
                                // FIXME: hide tooltip edit if there is no permission
                                {...vehicle}
                                onEditClick={this.handleEditClick}
                                onShowInventoryClick={this.handleShowInventoryClick}
                            />
                        </MapTooltip>
                    )}
                    <MapState
                        attributes={mapVehicleHoverAttributes}
                        attributeKey="hover"
                    />
                </MapSource>

                {/* Ambulance  */}
                <MapSource
                    sourceKey="ambulance-points"
                    sourceOptions={{ type: 'geojson' }}
                    geoJson={pointFeatureCollectionAmbulance}
                >
                    <MapLayer
                        layerKey="ambulance-points-fill"
                        layerOptions={{
                            type: 'circle',
                            paint: isVehicleHovered
                                ? mapStyles.vehiclePoint.dimFill
                                : mapStyles.vehiclePoint.fill,
                        }}
                        onClick={this.handleAmbulanceClick}
                        onMouseEnter={this.handleVehicleMouseEnter}
                        onMouseLeave={this.handleVehicleMouseLeave}
                        // onAnimationFrame={this.handleAnimationKeyframeRealtime}
                    />
                    {/* <MapLayer
                        layerKey="-vehicle-symbol-icon-fire-bridage"
                        layerOptions={{
                            type: 'symbol',
                            // filter: ['!', ['has', 'point_count']],
                            layout: {
                                'icon-image': 'truck',
                                'icon-size': 0.016,
                            },
                        }}
                    /> */}
                    <MapLayer
                        layerKey="-ambulance-symbol-icon-fire"
                        layerOptions={{
                            type: 'symbol',
                            paint: isVehicleHovered ? mapStyles.vehiclePoint.symbolFill
                                : mapStyles.vehiclePoint.symbolDim,
                            layout: {
                                // 'icon-image': ['get', 'iconStatus'],
                                'icon-image': 'ambulance',
                                'icon-size': 0.29,
                                // 'icon-opacity': isVehicleHovered ? 1 : 0,
                            },
                        }}
                    />

                    { ambulanceLngLat && (

                        <MapTooltip
                            coordinates={ambulanceLngLat}
                            tooltipOptions={tooltipOptions}
                            onHide={this.handleAmbulanceClose}
                        >
                            <VehicleInfo
                                // FIXME: hide tooltip edit if there is no permission
                                {...ambulance}
                                onEditClick={this.handleEditClickAmbulance}
                                onShowInventoryClick={this.handleShowInventoryClick}
                            />
                        </MapTooltip>
                    )}
                    <MapState
                        attributes={mapVehicleHoverAttributes}
                        attributeKey="hover"
                    />
                </MapSource>

                {/* {testMarkers && (
                    <MapSource
                        sourceKey="realtime-vehicles"
                        sourceOptions={{ type: 'geojson' }}
                        geoJson={testMarkers}
                    >
                        <MapLayer
                            layerKey="incident-points-fill"
                            layerOptions={{
                                type: 'circle',
                                paint: isHovered
                                    ? mapStyles.incidentPoint.dimFill
                                    : mapStyles.incidentPoint.fill,
                            }}
                            onClick={this.handleIncidentClick}
                            onMouseEnter={this.handleIncidentMouseEnter}
                            onMouseLeave={this.handleIncidentMouseLeave}
                        />
                    </MapSource>
                )} */}

                {getHashFromBrowser() !== 'vehicles' && (
                    <MapSource
                        sourceKey="incident-points"
                        sourceOptions={{ type: 'geojson' }}
                        geoJson={pointFeatureCollection}
                    >
                        <MapLayer
                            layerKey="incident-points-animate"
                            layerOptions={{
                                type: 'circle',
                                filter,
                                paint: mapStyles.incidentPoint.animatedFill,
                                layout: isHovered
                                    ? noneLayout
                                    : visibleLayout,
                            }}
                            onAnimationFrame={this.handleAnimationKeyframe}
                        />
                        <MapLayer
                            layerKey="incident-points-fill"
                            layerOptions={{
                                type: 'circle',
                                paint: isHovered
                                    ? mapStyles.incidentPoint.dimFill
                                    : mapStyles.incidentPoint.fill,
                            }}
                            onClick={this.handleIncidentClick}
                            onMouseEnter={this.handleIncidentMouseEnter}
                            onMouseLeave={this.handleIncidentMouseLeave}
                        />
                        {/*
                    <MapLayer
                        layerKey="incident-point-icon"
                        layerOptions={{
                            type: 'symbol',
                            layout: {
                                'icon-image': ['get', 'hazardIcon'],
                                'icon-size': 0.2,
                            },
                            paint: isHovered
                                ? { 'icon-opacity': 0.1 }
                                : { 'icon-opacity': 0.9 },
                        }}
                    />
                    */}
                        { incidentLngLat && (
                            <MapTooltip
                                coordinates={incidentLngLat}
                                tooltipOptions={tooltipOptions}
                                onHide={this.handleIncidentClose}
                            >
                                <IncidentInfo
                                    incident={incident}
                                    wardsMap={wardsMap}
                                    provincesMap={provincesMap}
                                    districtsMap={districtsMap}
                                    municipalitiesMap={municipalitiesMap}
                                    className={styles.incidentInfo}
                                    showEditIncident={showEditIncident}
                                    showDeleteIncident={showDeleteIncident}
                                    onEditIncident={this.handleEditIncidentClick}
                                    onDeleteIncident={this.handleIncidentDelete}
                                    incidentDeletePending={incidentDeletePending}
                                />
                            </MapTooltip>
                        )}
                        <MapState
                            attributes={mapHoverAttributes}
                            attributeKey="hover"
                        />
                    </MapSource>
                )}

                { dispatchedVehicleFeatureColl && (
                    <MapSource
                        sourceKey="vehicle-dispatched-points"
                        sourceOptions={{ type: 'geojson' }}
                        geoJson={dispatchedVehicleFeatureColl}
                    >
                        <MapLayer
                            layerKey="vehicle-dispatched-points-fill"
                            layerOptions={{
                                type: 'circle',
                                paint: isVehicleHovered
                                    ? mapStyles.vehiclePoint.dimFill
                                    : mapStyles.vehiclePoint.fill,
                            }}
                            onClick={this.handleVehicleClick}
                            onMouseEnter={this.handleVehicleMouseEnter}
                            onMouseLeave={this.handleVehicleMouseLeave}
                            onAnimationFrame={this.handleAnimationKeyframeRealtime}

                        />
                        <MapLayer
                            layerKey="vehicle-dispatched-point-animated"
                            layerOptions={{
                                type: 'circle',
                                // filter,
                                paint: mapStyles.incidentPoint.animatedFill,
                                // layout: isAlertHovered || isEventHovered
                                layout: isVehicleHovered
                                    ? noneLayout
                                    : visibleLayout,
                            }}
                            // onAnimationFrame={this.handleAnimationKeyframe}
                        />
                        <MapLayer
                            layerKey="-vehicle-dispatched-symbol-icon-fire-bridage"
                            layerOptions={{
                                type: 'symbol',
                                // filter: ['!', ['has', 'point_count']],
                                layout: {
                                    'icon-image': 'truck',
                                    'icon-size': 0.016,
                                },
                            }}
                        />
                        {/* <MapState
                        attributes={mapVehicleHoverAttributes}
                        attributeKey="hover"
                    /> */}
                    </MapSource>
                )}


                { showVehicleForm && (
                    <AddResourceForm
                        closeModal={this.handleEditResourceFormCloseButtonClick}
                        resourceId={vehicle.id}
                        resourceDetails={{ ...vehicle, resourceType: 'fireengine' }}
                        onEditSuccess={this.handleResourceEdit}
                    />

                )}
                { showAmbulanceForm && (
                    <AddResourceForm
                        closeModal={this.handleEditResourceFormCloseButtonClick}
                        resourceId={ambulance.id}
                        resourceDetails={{ ...ambulance, resourceType: 'ambulance' }}
                        onEditSuccess={this.handleResourceEdit}
                    />

                )}
                { showInventoryModal && (
                    // {showInventoryModal
                    //     && isDefined(resourceDetails)
                    //     && isDefined(resourceDetails.id)
                    //     && (
                    <InventoriesModal
                        resourceId={vehicle && vehicle.id ? vehicle.id : ambulance.id}
                        closeModal={this.handleInventoryModalClose}
                    />
                        // )
                    // }
                // closeModal={handleEditResourceFormCloseButtonClick}

                )}
                {showEditIncidentModal && (
                    <AddIncidentForm
                        lossServerId={lossServerId}
                        incidentServerId={incidentServerId}
                        incidentDetails={incident}
                        onIncidentChange={this.handleIncidentEdit}
                        onLossChange={this.handleLossEdit}
                        closeModal={this.handleCloseEditModal}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    createRequestClient(requestOptions)(IncidentMap),
);
