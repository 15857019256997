import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Modal from '#rscv/Modal';
import ModalHeader from '#rscv/Modal/Header';
import ModalBody from '#rscv/Modal/Body';
import DangerButton from '#rsca/Button/DangerButton';
import styles from './styles.scss';
import TabularView from './TabularView';
import DownloadButton from '#components/DownloadButton';
import { getCitizenReports } from './services';
import { returnTitleFromId } from '#utils/common';

const CitizenReports = (props) => {
    const [apiData, setApiData] = useState('');
    const [viewPermission, setViewPermission] = useState(false);
    const incidentTypesObjcts = useSelector(state => state.page.hazardTypes);
    const { userPermissions } = useSelector(state => state.auth.user);
    const incidentTypes = Object.keys(incidentTypesObjcts).map(key => incidentTypesObjcts[key]);
    const { closeModal } = props;

    useEffect(() => {
        const checkViewPermission = userPermissions.filter(item => item.app === 'incident' && item.codename === 'view_citizenreport');
        if (checkViewPermission && checkViewPermission.length > 0) {
            getCitizenReports().then((res) => {
                const { results } = res.data;
                const tempArray = [];
                results.forEach((data, index) => {
                    const date = data.createdOn.slice(0, 10);
                    const time = data.createdOn.slice(11, 19);
                    const serial = index + 1;
                    const hazard = returnTitleFromId(incidentTypes, data.hazard);
                    const details = 'View Details';
                    tempArray.push({ ...data, date, time, serial, details, hazard });
                });
                setApiData(tempArray);
            });
            setViewPermission(true);
        }
    }, [userPermissions]);

    return (
        <Modal className={styles.incidentTableModal} closeOnEscape>
            <ModalHeader
                title="Citizen Report"
                rightComponent={(
                    <DangerButton
                        transparent
                        iconName="close"
                        onClick={closeModal}
                        title="Close Modal"
                    />
                )}
            />
            <ModalBody className={styles.body}>
                <TabularView
                    className={styles.table}
                    reportList={apiData}
                    closeModal={closeModal}
                    incidentTypes={incidentTypes}
                    viewPermission={viewPermission}
                />
            </ModalBody>
            <div style={{ margin: '15px' }}>
                <DownloadButton value={apiData} name="Reports">
                    Download csv
                </DownloadButton>
            </div>
        </Modal>
    );
};

export default CitizenReports;
