/* eslint-disable no-nested-ternary */
import { isDefined, isNotDefined, isObject, isList } from '@togglecorp/fujs';

import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import { AnyLayout } from 'mapbox-gl';
import { any } from 'prop-types';

interface Row {
    [key: string]: string | number | boolean | undefined | null;
}

export const convertJsonToCsv = (
    data: Row[] | undefined | null,
    columnDelimiter = ',',
    lineDelimiter = '\n',
    emptyValue = '',
) => {
    if (!data || data.length <= 0) {
        return undefined;
    }

    // TODO: get exhaustive keys
    const keys = Object.keys(data[0]);

    let result = keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach((item) => {
        result += keys
            .map(key => item[key])
            .map((str) => {
                if (isNotDefined(str)) {
                    return emptyValue;
                }
                const val = String(str);
                if (val.includes(columnDelimiter)) {
                    return `"${val}"`;
                }
                return val;
            })
            .join(columnDelimiter);
        result += lineDelimiter;
    });

    return result;
};

export const toTitleCase = (str: string | undefined | null) => {
    if (isNotDefined(str)) {
        return undefined;
    }
    return String(str).replace(/(^|\s)\S/g, t => t.toUpperCase());
};

export const forEach = (obj: object, func: (key: string, val: any) => void) => {
    Object.keys(obj).forEach((key) => {
        const val = (obj as any)[key];
        func(key, val);
    });
};

export const sanitizeResponse = (data: unknown): any => {
    if (data === null || data === undefined) {
        return undefined;
    }
    if (isList(data)) {
        return data.map(sanitizeResponse).filter(isDefined);
    }
    if (isObject(data)) {
        let newData = {};
        forEach(data, (k, val) => {
            const newEntry = sanitizeResponse(val);
            if (newEntry !== null && newEntry !== undefined) {
                newData = {
                    ...newData,
                    [k]: newEntry,
                };
            }
        });
        return newData;
    }
    return data;
};

interface KeyFunc<T, Q> {
    (val: T): Q;
}

function groupListRaw<T>(lst: T[] = [], getKey: KeyFunc<T, string | number>) {
    const mem: {
        [key: string]: {
            key: string | number;
            value: T[];
        };
    } = {};
    lst.forEach((item) => {
        const key = getKey(item);
        if (!mem[key]) {
            mem[key] = {
                key,
                value: [],
            }; // eslint-disable-line no-param-reassign
        }
        mem[key].value.push(item);
    });
    return mem;
}

export function groupList<T>(lst: T[] = [], getKey: KeyFunc<T, string | number>) {
    const mem = groupListRaw(lst, getKey);
    return Object.values(mem);
}

export function groupFilledList<T>(lst: T[] = [], getKey: KeyFunc<T, number>) {
    const mem = groupListRaw(lst, getKey);

    const identifierList = lst.map(getKey);
    const start = Math.min(...identifierList);

    const end = Math.max(...identifierList);
    const output = [];
    for (let i = start; i <= end; i += 1) {
        output.push(mem[i] || { key: i, value: [] });
    }
    return output;
}

export function sum(list: number[]) {
    return list.reduce((acc, val) => acc + (isDefined(val) ? val : 0), 0);
}

export function getYmd(dateString: string | number | undefined) {
    if (!dateString) {
        return undefined;
    }
    const date = new Date(dateString);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function getYesterday(days = 0) {
    const date = new Date();
    date.setDate(date.getDate() - days);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date.getTime();
}

interface FrameFunction<T> {
    (percent: number, timestamp: number): T;
}

export function framize<T>(fn: FrameFunction<T>, duration = 2000) {
    let prevTimestamp: number;
    return (timestamp: number) => {
        if (!prevTimestamp) {
            prevTimestamp = timestamp;
        }
        const diff = timestamp - prevTimestamp;
        if (diff > duration) {
            prevTimestamp = timestamp;
        }
        const percent = (timestamp - prevTimestamp) / duration;
        return fn(percent, timestamp);
    };
}

export const getImageAsync = (src: string, width = 56, height = 56) => {
    const image = new Image(width, height);
    image.setAttribute('crossOrigin', '');

    image.src = src;

    return new Promise((resolve) => {
        image.onload = () => {
            resolve(image);
        };
    });
};

export function getImage(src: string, width = 56, height = 56) {
    const image = new Image(width, height);
    image.src = src;

    return image;
}

export const encodeTime = (date: Date) => `${date.getHours()}:${date.getMinutes()}:00`;

export { encodeDate } from '@togglecorp/fujs';

export const imageUrlToDataUrl = (
    url: string | URL,
    callback: { (dataUrl: string): void; (arg0: string | ArrayBuffer | null): void },
) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };

        reader.readAsDataURL(xhr.response);
    };

    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
};

export function saveChart(elementId: string, name: string) {
    domtoimage
        .toBlob(document.getElementById(elementId))
        .then((blob: string | Blob) => saveAs(blob, `${name}.png`));
}

export const arrayGroupBy = (array: any[], key: any) => array.reduce((result, currentValue) => {
    // eslint-disable-next-line no-param-reassign
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
}, {});

export const httpGet = (url: string) => {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.open('GET', url, false); // false for synchronous request
    xmlHttp.send(null);
    return xmlHttp.response;
};

export function camelCaseToSnake(str: string) {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

export const camelCaseToSnakeCaseObject = (data: any) => Object.keys(data).reduce(
    (obj, item): any => ({
        ...obj,
        [camelCaseToSnake(item)]:
                !!data[item] && typeof data[item] === 'object'
                    ? camelCaseToSnakeCaseObject(data[item])
                    : data[item],
    }),
    {},
);

export const sortByKey = (array: any, key: any, type: any) => array.sort((a: any, b: any) => {
    const x = a[key];
    const y = b[key];
    if (type === 'ascending') {
        return x < y ? -1 : x > y ? 1 : 0;
    }
    return x > y ? -1 : x < y ? 1 : 0;
});

// compares array of objects

export const compareArray = (a: any, b: any) => {
    if (a.length !== b.length) return false;
    const uniqueValues = new Set([...a, ...b]);
    // eslint-disable-next-line no-restricted-syntax
    for (const v of uniqueValues) {
        const aCount = a.filter((e: any) => e === v).length;
        const bCount = b.filter((e: any) => e === v).length;
        if (aCount !== bCount) return false;
    }
    return true;
};

// compares array of objects based on a specific key
// return true when arrays are equal but the index of objects are different
export const compareArrayWithPositioning = (arr1: any, arr2: any, key: any) => {
    if (arr1.length !== arr2.length) return false;
    const arrayOne = arr1.map((item: { [x: string]: any }) => item[key]);
    const arrayTwo = arr2.map((item: { [x: string]: any }) => item[key]);
    const arrayOneClone = arrayOne.map((a: any) => a);
    const arrayTwoClone = arrayTwo.map((a: any) => a);
    if (arrayOne.sort().join(',') === arrayTwo.sort().join(',')) {
        if (JSON.stringify(arrayOneClone) !== JSON.stringify(arrayTwoClone)) {
            return true;
        }
    }
    return false;
};

// user object from redux, example codeName="edit_resource", app="resource"
export const checkPermission = (
    user: { isSuperuser: any; groups: any[]; userPermissions: any[] },
    codeName: string,
    app: string,
) => {
    let permission = false;
    if (!user) {
        permission = false;
    } else if (user.isSuperuser) {
        permission = true;
    }
    if (user && user.groups) {
        user.groups.forEach((group: { permissions: any[] }) => {
            if (group.permissions) {
                group.permissions.forEach((p: { codename: any; app: any }) => {
                    if (p.codename === codeName && p.app === app) {
                        permission = true;
                    }
                });
            } else {
                permission = false;
            }
        });
    }
    if (user && user.userPermissions) {
        user.userPermissions.forEach((a: { codename: any; app: any }) => {
            if (a.codename === codeName && a.app === app) {
                permission = true;
            }
        });
    } else {
        permission = false;
    }
    return permission;
    // temporary set true to all user for testing
    // return true;
};

// This function can be used to compare login in user's
// federal region and filtered federal region to check the accessibility of the data.
export const checkSameRegionPermission = (
    user: { isSuperuser: any; profile: { province: null; district: null; municipality: null } },
    region: { adminLevel: number; geoarea: any },
) => {
    let permission = false;
    if (user && user.isSuperuser) {
        permission = true;
    } else if (region.adminLevel === 1) {
        if (
            user
            && user.profile.province === region.geoarea
            && user.profile.district === null
            && user.profile.municipality === null
        ) {
            permission = true;
        }
    } else if (region.adminLevel === 2) {
        if (user && user.profile.district === region.geoarea && user.profile.province === null) {
            permission = true;
        }
    } else if (region.adminLevel === 3) {
        if (user && user.profile.municipality === region.geoarea) {
            permission = true;
        }
    } else {
        permission = false;
    }
    return permission;
};

export const returnTitleFromId = (array: any[], id: any) => {
    try {
        return array.find((item: { id: any }) => item.id === id).title;
    } catch {
        return id;
    }
};

export const returnIdFromTitle = (array: any[], title: any) => {
    try {
        return array.find((item: { title: any }) => item.title === title).id;
    } catch {
        return title;
    }
};
