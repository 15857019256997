import { createSelector } from 'reselect';
import { AppState } from '../../types';

import { IncidentState } from './types';

export const incidentStateSelector = ({ incident }: AppState): IncidentState => incident;

export const notificationSelector = createSelector(
    incidentStateSelector,
    incident => incident.notificationId,
);
