/* eslint-disable */
import React, { useState, useEffect } from 'react';

import styling from './styles.scss';
import styles from '../styles.scss';
import { linkCitizenToIncident } from '../../services';
import Icon from '#rscg/Icon';
import usePagination from '#utils/usePagination';
import { returnTitleFromId } from '#utils/common';

export default function Table(props) {
    const [checkState, setCheckState] = useState([]);
    const { incidentList, incident, setLinked, closeModal, incidentTypes, setIncidentId } = props;
    const { handelSorting } = usePagination(incidentList && incidentList);

    useEffect(() => {
        if (props.incidentList.length > 0) {
            const list = props.incidentList.map((incident) => false);
            setCheckState(list);
        }
    }, [props]);

    const handelCheckBox = (ind) => {
        const list = checkState;
        const newList = list.map((item, index) => {
            if (index === ind) return true;
            return false;
        });
        setCheckState(newList);
    };

    const linkIncident = () => {
        let selectedIndex;
        // eslint-disable-next-line no-shadow
        const index = checkState.forEach((item, index) => {
            if (item === true) return (selectedIndex = index);
        });
        if (selectedIndex !== undefined) {
            const selectedIncident = incidentList[selectedIndex];
            linkCitizenToIncident(incident.id, selectedIncident.id).then((res) => {
                setLinked(true);
                setIncidentId(selectedIncident.id);
            });
        }
    };

    return (
        <div className={styling.citizenReportTable}>
            <div className={styling.citizenReportTableWrapper}>
                <thead style={{ marginTop: '5vh' }}>
                    <tr>
                        <th scope="col" style={{ width: '3vw' }} />
                        <th scope="col" style={{ width: '4vw' }}>
                            <Icon
                                name="sort"
                                style={{ paddingRight: '5px' }}
                                onClick={() => {
                                    handelSorting('serial');
                                }}
                            />
                            S.N.
                        </th>
                        <th scope="col" style={{ width: '6vw' }}>
                            <Icon name="sort" style={{ paddingRight: '5px' }} />
                            Incident Type
                        </th>
                        <th scope="col" style={{ width: '10vw' }}>
                            <Icon name="sort" style={{ paddingRight: '5px' }} />
                            Title
                        </th>
                        <th scope="col" style={{ width: '5vw' }}>
                            <Icon
                                name="sort"
                                style={{ paddingRight: '5px' }}
                                onClick={() => {
                                    handelSorting('date');
                                }}
                            />
                            Date
                        </th>
                        <th scope="col" style={{ width: '5vw' }}>
                            <Icon
                                name="sort"
                                style={{ paddingRight: '5px' }}
                                onClick={() => {
                                    handelSorting('time');
                                }}
                            />
                            Time
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {incidentList &&
                        incidentList.map((item, index) => (
                            <tr key={item.serial}>
                                <td data-label="Account">
                                    <input
                                        type="checkbox"
                                        onChange={() => handelCheckBox(index)}
                                        checked={checkState[index]}
                                    />
                                </td>
                                <td>{item.serial}</td>
                                <td>{returnTitleFromId(incidentTypes, item.hazard)}</td>
                                <td>{item.title}</td>
                                <td>{item.date}</td>
                                <td>{item.time}</td>
                            </tr>
                        ))}
                    {!incidentList && <div>No nearby incidents reported</div>}
                </tbody>
            </div>
            {incidentList && (
                <div className={styles.buttonSection}>
                    <div
                        className={styles.button}
                        onClick={() => {
                            linkIncident();
                        }}
                    >
                        Link Incident
                    </div>
                    <div className={styles.button} onClick={closeModal}>
                        {' '}
                        Discard{' '}
                    </div>
                </div>
            )}
        </div>
    );
}
