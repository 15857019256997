/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.scss';
import ScrollTabs from '#rscv/ScrollTabs';
import Create from './CreateIncident';
import LinkIncident from './LinkIncident/table';
import { returnTitleFromId } from '#utils/common';
import DefaultImage from '#resources/images/openspace-images/default_image.png';

export default function ReportSection(props) {
    const [currentView, setCurrentView] = useState('create');
    const [incidentId, setIncidentId] = useState();
    const [addPermission, setAddPermission] = useState(false);
    const [linked, setLinked] = useState(false);
    const [mainImage, setMainImage] = useState('');
    const { mainData, closeModal } = props;
    const { imageList } = props && props.imageList && props.imageList[0];
    const { nearbyIncidents, incidentTypes } = props;

    const { userPermissions } = useSelector(state => state.auth.user);

    const tabs = {
        create: 'Create an Incident',
        link: 'Link it with an incident',
    };

    const handleTabClick = (newTab) => {
        setCurrentView(newTab);
    };

    useEffect(() => {
        const checkViewPermission = userPermissions.filter(item => item.app === 'incident' && item.codename === 'add_incident');

        if (checkViewPermission && checkViewPermission.length > 0) {
            console.log('checkViewPermission', checkViewPermission);
            setAddPermission(true);
        }
    }, [userPermissions]);

    useEffect(() => {
        if (mainData && mainData.image) {
            setMainImage(mainData.image);
        } else {
            setMainImage(DefaultImage);
        }
        if (mainData.verified) {
            setLinked(true);
            setIncidentId(mainData.incident);
        }
    }, [mainData]);

    return (
        <div className={styles.about}>
            <h3>Citizen Request Details</h3>
            <div className={styles.clientImageSection}>
                <div className={styles.selectedImage}>
                    <img src={mainImage} alt={DefaultImage} className={styles.bigImage} />
                </div>
                {imageList && imageList.length > 0 && (
                    <div className={styles.imageList}>
                        {imageList.map(image => (
                            <img
                                src={image.image}
                                alt="cat"
                                className={styles.smallImage}
                                onClick={() => {
                                    setMainImage(image.image);
                                }}
                            />
                        ))}
                    </div>
                )}
            </div>
            <div className={styles.detailSection}>
                <div className={styles.detailsRow}>
                    <div className={styles.details}>
                        <div className={styles.detailsTitle}>Incident Type</div>
                        <div>{mainData && returnTitleFromId(incidentTypes, mainData.hazard)}</div>
                    </div>
                    <div className={styles.details}>
                        <div className={styles.detailsTitle}>Label</div>
                        <div>{mainData ? mainData.label : 'label'}</div>
                    </div>
                </div>
                <div className={styles.detailsRow}>
                    <div className={styles.details}>
                        <div className={styles.detailsTitle}>Phone Number</div>
                        <div>{mainData ? mainData.contactNumber : 'contactNumber'}</div>
                    </div>
                    <div className={styles.details}>
                        <div className={styles.detailsTitle}>Requested Vechicle</div>
                        <div>{mainData ? mainData.vehicle : 'label'}</div>
                    </div>
                </div>
                <div className={styles.detailsRow}>
                    <div className={styles.detailsFlexible}>
                        <div className={styles.detailsTitle}>Incident Details</div>
                        <div>{mainData ? mainData.description : 'description'}</div>
                    </div>
                </div>
            </div>
            {!linked && addPermission && (
                <>
                    <h3>Additional Details</h3>
                    <div>
                        <ScrollTabs
                            className={styles.tabs}
                            tabs={tabs}
                            active={currentView}
                            onClick={(e) => {
                                handleTabClick(e);
                            }}
                        />
                        {currentView === 'create' && (
                            <Create
                                incident={mainData}
                                setLinked={setLinked}
                                closeModal={closeModal}
                                incidentTypes={incidentTypes}
                                setIncidentId={setIncidentId}
                            />
                        )}

                        {currentView === 'link' && (
                            <LinkIncident
                                incidentList={nearbyIncidents}
                                className={styles.table}
                                incident={mainData}
                                setLinked={setLinked}
                                closeModal={closeModal}
                                incidentTypes={incidentTypes}
                                setIncidentId={setIncidentId}
                            />
                        )}
                    </div>
                </>
            )}
            {linked && incidentId && (
                <>
                    <div className={styles.linkedSection}>
                        <p>This citizen report has been liked with an Incident.</p>
                        <p
                            onClick={() => {
                                window.open(`/incidents/${incidentId}/response`);
                            }}
                            style={{ cursor: 'pointer', color: 'blue' }}
                        >
                            Go to incident response.
                        </p>
                    </div>
                </>
            )}
        </div>
    );
}
