/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './styles.scss';
import { setNotificationId } from '#store/atom/incident/actions';
import usePagination from '#utils/usePagination';
import Icon from '#rscg/Icon';

export default function Table(props) {
    const { reportList, viewPermission } = props;
    const dispatch = useDispatch();
    const { handelSorting } = usePagination(reportList && reportList);

    const viewDetails = (id) => {
        dispatch(setNotificationId(id));
        props.closeModal();
    };

    return (
        <div className={styles.citizenReportTable}>
            <div className={styles.citizenReportTableWrapper}>
                <thead>
                    <tr>
                        <th
                            scope="col"
                            onClick={() => {
                                handelSorting('serial');
                            }}
                            style={{ width: '4vw' }}
                        >
                            <Icon name="sort" style={{ paddingRight: '5px' }} />
                            S.N.
                        </th>
                        <th
                            scope="col"
                            onClick={() => {
                                handelSorting('incident');
                            }}
                            style={{ width: '6vw' }}
                        >
                            <Icon name="sort" style={{ paddingRight: '5px' }} />
                            Incident Type
                        </th>
                        <th
                            scope="col"
                            onClick={() => {
                                handelSorting('label');
                            }}
                            style={{ width: '7vw' }}
                        >
                            <Icon name="sort" style={{ paddingRight: '5px' }} />
                            label
                        </th>
                        <th
                            scope="col"
                            onClick={() => {
                                handelSorting('description');
                            }}
                        >
                            <Icon name="sort" style={{ paddingRight: '5px' }} />
                            Details
                        </th>
                        <th
                            scope="col"
                            onClick={() => {
                                handelSorting('date');
                            }}
                            style={{ width: '8vw' }}
                        >
                            <Icon name="sort" style={{ paddingRight: '5px' }} />
                            Date
                        </th>
                        <th
                            scope="col"
                            onClick={() => {
                                handelSorting('time');
                            }}
                            style={{ width: '8vw' }}
                        >
                            <Icon name="sort" style={{ paddingRight: '5px' }} />
                            Reported Time
                        </th>
                        <th
                            scope="col"
                            onClick={() => {
                                handelSorting('verified');
                            }}
                            style={{ width: '7vw' }}
                        >
                            <Icon name="sort" style={{ paddingRight: '5px' }} />
                            Status
                        </th>
                        <th scope="col" style={{ width: '6vw' }} />
                    </tr>
                </thead>
                <tbody>
                    {reportList
                        && reportList.map(report => (
                            <tr key={report.id}>
                                <td>{report.serial}</td>
                                <td>{report.hazard}</td>
                                <td>{report.label}</td>
                                <td>{report.description}</td>
                                <td>{report.date}</td>
                                <td>{report.time}</td>
                                <td>{report.verified ? 'Verified' : 'Not verified'}</td>
                                <td
                                    onClick={() => viewDetails(report.id)}
                                    style={{ fontWeight: 'bold', cursor: 'pointer' }}
                                >
                                    <Icon name="info" style={{ paddingRight: '5px' }} />
                                    View Details
                                </td>
                            </tr>
                        ))}
                    {!reportList && viewPermission && <div>Data not available! </div>}
                </tbody>
                {!viewPermission && <div>User not permitted to view citizen reports! </div>}
            </div>
        </div>
    );
}
