// eslint-disable-next-line import/no-self-import
import firebase from 'firebase';
import axios from 'axios';

const config = {
    apiKey: 'AIzaSyDpEgCgFhrT2O9pVU4RCpK646LX2J3AVbQ',
    authDomain: 'bipad-90579.firebaseapp.com',
    projectId: 'bipad-90579',
    storageBucket: 'bipad-90579.appspot.com',
    messagingSenderId: '705873563395',
    appId: '1:705873563395:web:f5496af26d6b161f052336',
};

const firebaseApp = firebase.initializeApp(config);
export default firebaseApp;

const message = firebaseApp.messaging();
export { message };

const baseUrl = process.env.REACT_APP_API_SERVER_URL;

export const registerDevice = (deviceId, userId) => {
    const formdata = new FormData();
    formdata.append('device_id', deviceId);
    formdata.append('action', 'create');
    return axios({
        method: 'POST',
        url: `${baseUrl}/notification-update-id/${userId}/`,
        data: formdata,
    });
};

export const getToken = (userId) => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .register('/firebase-messaging-sw.js')
            .then(() => {
                Notification.requestPermission()
                    .then(() => {
                        const token = message.getToken();
                        return token;
                    })
                    .then((token) => {
                        registerDevice(token, userId);
                        console.log('device token registration complete');
                    })
                    .catch((err) => {
                        console.log('err receiving token', err);
                    });
            })
            .catch((err) => {
                console.log('Service worker registration failed, error:', err);
            });
    }
};
