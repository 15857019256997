/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';


import { mapToList } from '@togglecorp/fujs';
import AccentButton from '#rsca/Button/AccentButton';
import TextOutput from '#components/TextOutput';
import styles from './styles.scss';
import ListView from '#rsu/../v2/View/ListView';

const camelCaseToSentence = (text) => {
    const result = text.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

    return finalResult;
};

const VehicleInfo = (props) => {
    const { onEditClick, onShowInventoryClick, ...resourceDetails } = props;

    const { id, point, title, ...resource } = resourceDetails;


    const data = mapToList(
        resource,
        (value, key) => ({ label: key, value }),
    );

    const rendererParams = (_, item) => ({
        className: styles.item,
        labelClassName: styles.label,
        valueClassName: styles.value,
        ...item,
        label: camelCaseToSentence(item.label),
    });

    let filtered = data;

    filtered = data.filter(x => x.label !== 'responderDetail');

    const resourceKeySelector = d => d.label;
    return (
        <div className={styles.resourceTooltip}>
            <h3 className={styles.heading}>
                {title}
            </h3>
            <ListView
                className={styles.content}
                data={filtered}
                keySelector={resourceKeySelector}
                renderer={TextOutput}
                rendererParams={rendererParams}
            />
            <div className={styles.actions}>
                <AccentButton
                    title="Edit"
                    onClick={onEditClick}
                    transparent
                    className={styles.editButton}
                >
                    Edit data
                </AccentButton>
                <AccentButton
                    title={
                        resourceDetails.resourceType === 'openspace'
                       || resourceDetails.resourceType === 'communityspace'
                            ? 'View Details'
                            : 'Show Inventory'
                    }
                    onClick={onShowInventoryClick}
                    transparent
                    className={styles.editButton}
                >
                    { resourceDetails.resourceType === 'openspace'
                     || resourceDetails.resourceType === 'communityspace'
                        ? 'View Details'
                        : 'Show Inventory'}
                </AccentButton>
            </div>
        </div>
    );
};
export default VehicleInfo;
