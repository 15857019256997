import React, { FunctionComponent } from 'react';

import TextInput from '#rsci/TextInput';

const IndustryFields: FunctionComponent = () => (
    <TextInput
        faramElementName="type"
        label="Type"
    />
);

export default IndustryFields;
