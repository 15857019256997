const AllActionTypes = {
    SET_NOTIFICATION_ID: 'SET_NOTIFICATION_ID',
    REMOVE_NOTIFICATION_ID: 'REMOVE_NOTIFICATION_ID',

    GET_VEHICLE_LIST_REQUEST: 'GET_VEHICLE_LIST_REQUEST',
    GET_VEHICLE_LIST_SUCCESS: 'GET_VEHICLE_LIST_SUCCESS',
    GET_VEHICLE_LIST_FAILURE: 'GET_VEHICLE_LIST_FAILURE',

    SET_DISPATCH_ID: 'SET_DISPATCH_ID',
};

export default AllActionTypes;

export interface IncidentState {
    notificationId: string | number;
}
