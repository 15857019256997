const incidentCircleRadii = [
    'case',
    ['==', ['get', 'severity'], 'Minor'], 8,
    ['==', ['get', 'severity'], 'Major'], 11,
    ['==', ['get', 'severity'], 'Severe'], 15,
    ['==', ['get', 'severity'], 'Catastrophic'], 20,
    8,
];
const vehicleStatusCircleColor = [
    'case',
    ['==', ['get', 'status'], 'idle'], 'gray',
    ['==', ['get', 'status'], 'available'], '#4CAF50',
    ['==', ['get', 'status'], 'not_available'], '#FF5722',
    ['==', ['get', 'status'], 'deployed'], '#2196F3',
    ['==', ['get', 'status'], 'rejected'], '#F44336',
    ['==', ['get', 'status'], 'completed'], '#8BC34A',
    ['==', ['get', 'status'], 'assigned'], '#FFC107',
    '#4c4c4c',
];

const resourceClusterInitial = {
    'circle-color': '#ffeb3b',
    'circle-radius': [
        'step',
        ['get', 'point_count'],
        10,
        100,
        20,
        750,
        30,
    ],
    'circle-stroke-width': 1,
    'circle-stroke-color': '#000000',
    'circle-stroke-opacity': 0.3,
};

const resourcePointInitial = {
    'circle-color': '#ffffff',
    'circle-radius': 13,
    'circle-opacity': 0.9,
    'circle-stroke-width': ['case',
        ['boolean', ['feature-state', 'hover'], false],
        2,
        1,
    ],
    'circle-stroke-color': '#000000',
    'circle-stroke-opacity': 0.3,
};

export default {
    province: {
        outline: {
            'line-color': '#004d40',
            'line-width': 1,
        },
        choroplethOutline: {
            'line-color': '#000000',
            'line-width': 1,
        },
        fill: {
            'fill-color': '#000000',
            'fill-opacity': 0.1,
        },
    },
    district: {
        outline: {
            'line-color': '#004d40',
            'line-width': 1,
        },
        choroplethOutline: {
            'line-color': '#000000',
            'line-width': 0.6,
            'line-opacity': 0.8,
        },
        fill: {
            'fill-color': '#000000',
            'fill-opacity': 0.1,
        },
    },
    municipality: {
        outline: {
            // 'line-color': '#72b6ac',
            'line-color': '#C1292E',
            'line-width': 1,
        },
        choroplethOutline: {
            'line-color': '#000000',
            'line-width': 0.27,
            'line-opacity': 0.6,
        },
        fill: {
            'fill-color': '#000000',
            'fill-opacity': 0.1,
        },
    },
    ward: {
        outline: {
            // 'line-color': '#d0e8e4',
            'line-color': '#9883E5',
            'line-width': 1,
        },
        choroplethOutline: {
            'line-color': '#000000',
            'line-width': 0.1,
            'line-opacity': 0.5,
        },
        fill: {
            'fill-color': '#000000',
            'fill-opacity': 0.1,
        },
    },
    provinceLabel: {
        paint: {
            'text-color': '#002121',
            'text-halo-color': 'rgba(255, 255, 255, 0.7)',
            'text-halo-width': 2,
        },
        layout: {
            visibility: 'visible',
            // 'text-allow-overlap': true,
            'text-font': ['Rubik Bold'],
            'text-field': ['get', 'title'],
            'text-size': 12,
            'text-transform': 'uppercase',
            'text-justify': 'center',
            'text-anchor': 'center',
        },
    },
    districtLabel: {
        paint: {
            'text-color': '#00695c',
            'text-halo-color': 'rgba(255, 255, 255, 0.7)',
            'text-halo-width': 2,
        },
        layout: {
            visibility: 'visible',
            'text-font': ['Rubik Bold'],
            'text-field': ['get', 'title'],
            'text-size': 11,
            'text-justify': 'center',
            'text-anchor': 'center',
        },
    },
    municipalityLabel: {
        paint: {
            'text-color': '#00695c',
            'text-halo-color': 'rgba(255, 255, 255, 0.7)',
            'text-halo-width': 2,
        },
        layout: {
            visibility: 'visible',
            'text-font': ['Rubik Bold'],
            'text-field': ['get', 'title'],
            'text-size': 10,
            'text-justify': 'center',
            'text-anchor': 'center',
        },
    },
    wardLabel: {
        paint: {
            'text-color': '#1c9688',
            'text-halo-color': 'rgba(255, 255, 255, 0.7)',
            'text-halo-width': 2,
        },
        layout: {
            visibility: 'visible',
            'text-font': ['Rubik Bold'],
            'text-field': ['get', 'title'],
            'text-size': 10,
            'text-justify': 'center',
            'text-anchor': 'center',
        },
    },

    alertPoint: {
        circle: {
            'circle-color': ['get', 'hazardColor'],
            'circle-radius': 8,
            'circle-stroke-color': '#000000',
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                3,
                1,
            ],
            'circle-opacity': 0.9,
        },
        circleDim: {
            'circle-color': ['get', 'hazardColor'],
            'circle-radius': 8,
            'circle-stroke-color': '#000000',
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                3,
                0,
            ],
            'circle-opacity': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0.1,
            ],
        },
        animatedCircle: {
            'circle-color': ['get', 'hazardColor'],

            'circle-radius': 0,
            'circle-opacity': 0,

            'circle-radius-transition': { duration: 0 },
            'circle-opacity-transition': { duration: 0 },
            'circle-stroke-opacity': 0.2,
            'circle-stroke-color': ['get', 'hazardColor'],
            'circle-stroke-width': 1,
        },
    },
    alertConvex: {
        outline: {
            'line-color': ['get', 'hazardColor'],
            'line-width': 2,
            'line-dasharray': [2, 2],
            'line-offset': -6,
        },
    },
    alertPolygon: {
        fill: {
            'fill-color': ['get', 'hazardColor'],
            'fill-opacity': 0.3,
        },
        outline: {
            'line-color': ['get', 'hazardColor'],
            'line-width': 1,
            'line-dasharray': [2, 3],
        },
    },

    eventSymbol: {
        layout: {
            'text-field': '■',
            'text-allow-overlap': true,
            'text-size': 32,
        },
        paint: {
            'text-color': ['get', 'hazardColor'],
            'text-halo-color': '#000000',
            'text-halo-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                2,
                1,
            ],
            'text-opacity': 0.9,
        },
        paintDim: {
            'text-color': ['get', 'hazardColor'],
            'text-halo-color': '#000000',
            'text-halo-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                2,
                1,
            ],
            'text-opacity': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0.1,
            ],
        },
    },

    eventConvex: {
        outline: {
            'line-color': ['get', 'hazardColor'],
            'line-width': 1,
            'line-dasharray': [2, 2],
            'line-offset': -6,
        },
    },
    eventPolygon: {
        fill: {
            'fill-color': ['get', 'hazardColor'],
            'fill-opacity': 0.3,
        },
        outline: {
            'line-color': ['get', 'hazardColor'],
            'line-width': 1,
            'line-dasharray': [2, 3],
        },
    },

    vehiclePoint: {
        fill: {
            // 'circle-color': [...vehicleStatusCircleColor],
            'circle-color': '#FFFFFF',
            'circle-radius': 10,
            'circle-opacity': 0.8,
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                3,
                1,
            ],
            // 'circle-stroke-color': [...vehicleStatusCircleColor],
        },
        dimFill: {
            // 'circle-color': ['get', 'hazardColor'],
            // 'circle-radius': [...incidentCircleRadii],
            'circle-opacity': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0.1,
            ],
            // 'circle-stroke-color': [...vehicleStatusCircleColor],
            // 'circle-stroke-color': '#FFFFFF',
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                3,
                4,
            ],
        },
        symbolFill: {
            'icon-opacity': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0,
            ],
        },
        symbolDim: {
            'icon-opacity': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                0,
                1,
            ],
        },
    },
    incidentPoint: {
        fill: {
            'circle-color': ['get', 'hazardColor'],
            'circle-radius': [...incidentCircleRadii],
            'circle-opacity': 0.8,
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                3,
                1,
            ],
            'circle-stroke-color': '#414141',
        },
        dimFill: {
            'circle-color': ['get', 'hazardColor'],
            'circle-radius': [...incidentCircleRadii],
            'circle-opacity': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0.1,
            ],
            'circle-stroke-color': '#000000',
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                3,
                0,
            ],
        },
        animatedFill: {
            'circle-color': ['get', 'hazardColor'],

            'circle-radius': 0,
            'circle-opacity': 0,

            'circle-radius-transition': { duration: 0 },
            'circle-opacity-transition': { duration: 0 },
            'circle-stroke-opacity': 0.2,
            'circle-stroke-color': ['get', 'hazardColor'],
            'circle-stroke-width': 1,
        },
    },
    // FIXME: remove incident polygon
    incidentPolygon: {
        fill: {
            'fill-color': ['get', 'hazardColor'],
            'fill-opacity': 0.9,
            'fill-outline-color': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                '#000000',
                ['get', 'hazardColor'],
            ],
        },
    },
    vehicleRoute: {
        paint: {
            // 'line-color': '#2196F3',
            'line-color': [
                'case',
                ['==', ['get', 'status'], 'BELOW WARNING LEVEL'], '#FFC107',
                ['==', ['get', 'status'], 'ABOVE DANGER LEVEL'], '#2196F3',
                '#e53935',
            ],
            'line-width': 4,
        },
    },
    resourceCluster: {
        circle: {
            'circle-color': '#ffeb3b',
            'circle-radius': [
                'step',
                ['get', 'point_count'],
                10,
                100,
                20,
                750,
                30,
            ],
            'circle-stroke-width': 1,
            'circle-stroke-color': '#000000',
            'circle-stroke-opacity': 0.3,
        },
        education: {
            ...resourceClusterInitial,
            'circle-color': '#ffd046',
        },
        health: {
            ...resourceClusterInitial,
            'circle-color': '#EADAA2',
        },
        finance: {
            ...resourceClusterInitial,
            'circle-color': '#BD93BD',
        },
        governance: {
            ...resourceClusterInitial,
            'circle-color': '#82ddf0',
        },
        tourism: {
            ...resourceClusterInitial,
            'circle-color': '#7dcd85',
        },
        cultural: {
            ...resourceClusterInitial,
            'circle-color': '#c4b2bc',
        },
        industry: {
            ...resourceClusterInitial,
            'circle-color': '#a6a867',
        },
        communication: {
            ...resourceClusterInitial,
            'circle-color': '#efa8b8',
        },
        openspace: {
            ...resourceClusterInitial,
            'circle-color': '#297eff',
        },

        communityspace: {
            ...resourceClusterInitial,
            'circle-color': '#FEE94E',
        },
        fireengine: {
            ...resourceClusterInitial,
            'circle-color': '#F38171',
        },
        helipad: {
            ...resourceClusterInitial,
            'circle-color': '#BCE618',
        },
    },

    resourcePoint: {
        circle: {
            'circle-color': '#ffffff',
            'circle-radius': 13,
            'circle-opacity': 0.9,
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                2,
                1,
            ],
            'circle-stroke-color': '#000000',
            'circle-stroke-opacity': 0.3,
        },
        education: {
            ...resourcePointInitial,
            'circle-color': '#ffd046',
        },
        health: {
            ...resourcePointInitial,
            'circle-color': '#EADAA2',
        },
        finance: {
            ...resourcePointInitial,
            'circle-color': '#bd93bd',
        },
        governance: {
            ...resourcePointInitial,
            'circle-color': '#82ddf0',
        },
        tourism: {
            ...resourcePointInitial,
            'circle-color': '#7dcd85',
        },
        cultural: {
            ...resourcePointInitial,
            'circle-color': '#c4b2bc',
        },
        industry: {
            ...resourcePointInitial,
            'circle-color': '#a6a867',
        },
        communication: {
            ...resourcePointInitial,
            'circle-color': '#efa8b8',
        },
        openspace: {
            ...resourcePointInitial,
            'circle-color': '#297eff',
        },
        communityspace: {
            ...resourcePointInitial,
            'circle-color': '#FEE94E',
        },
        fireengine: {
            ...resourcePointInitial,
            'circle-color': '#F38171',
        },
        helipad: {
            ...resourcePointInitial,
            'circle-color': '#BCE618',
        },
    },

    resourceSymbol: {
        layout: {
            'icon-image': ['get', 'iconName'],
            'icon-size': 0.2,
        },
        symbol: {
            // 'icon-color': 'red',
        },
    },

    rainPoint: {
        circle: {
            'circle-color': [
                'case',
                // ['==', ['get', 'status'], 'BELOW WARNING LEVEL'], '#7CB342',
                ['==', ['get', 'status'], 'BELOW WARNING LEVEL'], '#2373a9',
                ['==', ['get', 'status'], 'ABOVE WARNING LEVEL'], '#FDD835',
                ['==', ['get', 'status'], 'ABOVE DANGER LEVEL'], '#e53935',
                '#000000',
            ],
            'circle-radius': 8,
            'circle-stroke-color': '#000000',
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                3,
                1,
            ],
            'circle-opacity': 0.9,
        },
        circleDim: {
            'circle-color': [
                'case',
                // ['==', ['get', 'status'], 'BELOW WARNING LEVEL'], '#7CB342',
                ['==', ['get', 'status'], 'BELOW WARNING LEVEL'], '#2373a9',
                ['==', ['get', 'status'], 'ABOVE WARNING LEVEL'], '#FDD835',
                ['==', ['get', 'status'], 'ABOVE DANGER LEVEL'], '#e53935',
                '#000000',
            ],
            'circle-radius': 8,
            'circle-stroke-color': '#000000',
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                3,
                0,
            ],
            'circle-opacity': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0.1,
            ],
        },
        paint: {
            'circle-radius': 8,
            'circle-color': [
                'case',
                // ['==', ['get', 'status'], 'BELOW WARNING LEVEL'], '#7CB342',
                ['==', ['get', 'status'], 'BELOW WARNING LEVEL'], '#2373a9',
                ['==', ['get', 'status'], 'ABOVE WARNING LEVEL'], '#FDD835',
                ['==', ['get', 'status'], 'ABOVE DANGER LEVEL'], '#e53935',
                '#000000',
            ],
            // 'circle-opacity': [
            //     'case',
            //     ['==', ['get', 'status'], 'BELOW WARNING LEVEL'], 0.3,
            //     ['==', ['get', 'status'], 'ABOVE WARNING LEVEL'], 1,
            //     ['==', ['get', 'status'], 'ABOVE DANGER LEVEL'], 1,
            //     0.2,
            // ],
            'circle-opacity': 1,
            'circle-stroke-color': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                '#000000',
                'rgba(0, 0, 0, 0)',
            ],
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0,
            ],
        },
    },

    rainSymbol: {
        layout: {
            'text-field': '▲',
            'text-allow-overlap': true,
            'text-size': 32,
        },
        paint: {
            'text-color': [
                'case',
                ['==', ['get', 'status'], 'BELOW WARNING LEVEL'], '#7CB342',
                ['==', ['get', 'status'], 'ABOVE WARNING LEVEL'], '#FDD835',
                ['==', ['get', 'status'], 'ABOVE DANGER LEVEL'], '#e53935',
                '#000000',
            ],
            'text-opacity': [
                'case',
                ['==', ['get', 'status'], 'BELOW WARNING LEVEL'], 1,
                ['==', ['get', 'status'], 'ABOVE WARNING LEVEL'], 1,
                ['==', ['get', 'status'], 'ABOVE DANGER LEVEL'], 1,
                0.3,
            ],
        },
    },

    riverPoint: {
        layout: {
            'text-field': [
                'case',
                // ['==', ['get', 'steady'], 'STEADY'], '●',
                ['==', ['get', 'steady'], 'STEADY'], '■',
                ['==', ['get', 'steady'], 'RISING'], '▲',
                ['==', ['get', 'steady'], 'FALLING'], '▼',
                '■',
            ],
            'text-allow-overlap': true,
            'text-size': 32,
        },
        text: {
            'text-color': [
                'case',
                ['==', ['get', 'status'], 'BELOW WARNING LEVEL'], '#7CB342',
                ['==', ['get', 'status'], 'ABOVE WARNING LEVEL'], '#FDD835',
                ['==', ['get', 'status'], 'ABOVE DANGER LEVEL'], '#e53935',
                '#000000',
            ],
            'text-halo-color': '#000',
            'text-halo-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                3,
                1,
            ],
            'text-opacity': 0.9,
        },
        textDim: {
            'text-color': [
                'case',
                ['==', ['get', 'status'], 'BELOW WARNING LEVEL'], '#7CB342',
                ['==', ['get', 'status'], 'ABOVE WARNING LEVEL'], '#FDD835',
                ['==', ['get', 'status'], 'ABOVE DANGER LEVEL'], '#e53935',
                '#000000',
            ],
            'text-halo-color': '#000',
            'text-halo-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                3,
                0,
            ],
            'text-opacity': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0.2,
            ],
        },
        paint: {
            'text-color': [
                'case',
                ['==', ['get', 'status'], 'BELOW WARNING LEVEL'], '#7CB342',
                ['==', ['get', 'status'], 'ABOVE WARNING LEVEL'], '#FDD835',
                ['==', ['get', 'status'], 'ABOVE DANGER LEVEL'], '#e53935',
                '#000000',
            ],
            // 'circle-radius': 8,
            // 'circle-color': [
            //     'case',
            //     ['==', ['get', 'status'], 'BELOW WARNING LEVEL'], '#7CB342',
            //     ['==', ['get', 'status'], 'ABOVE WARNING LEVEL'], '#FDD835',
            //     ['==', ['get', 'status'], 'ABOVE DANGER LEVEL'], '#e53935',
            //     '#000000',
            // ],
            // 'circle-opacity': [
            //     'case',
            //     ['==', ['get', 'steady'], 'STEADY'], 0.3,
            //     ['==', ['get', 'steady'], 'RISING'], 1,
            //     ['==', ['get', 'steady'], 'FALLING'], 1,
            //     0.3,
            // ],

            // 'circle-stroke-color': ['case',
            //     ['boolean', ['feature-state', 'hover'], false],
            //     '#000000',
            //     'rgba(0, 0, 0, 0)',
            // ],
            // 'circle-stroke-width': ['case',
            //     ['boolean', ['feature-state', 'hover'], false],
            //     1,
            //     0,
            // ],
        },
    },

    firePoint: {
        layout: {
            'text-field': '◆',
            'text-allow-overlap': true,
            'text-size': 24,
        },
        paint: {
            'circle-radius': 7,
            'circle-color': '#ff8300',
            'circle-opacity': 0.6,
            // 'circle-opacity': ['get', 'opacity'],
            'circle-stroke-color': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                '#000000',
                'rgba(0, 0, 0, 0)',
            ],
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0,
            ],
        },
        circle: {
            'circle-color': '#ff8300',
            'circle-radius': 8,
            'circle-stroke-color': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                '#000000',
                'rgba(0, 0, 0, 0)',
            ],
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                3,
                1,
            ],
            'circle-opacity': 0.6,
        },
        circleDim: {
            'circle-color': '#ff8300',
            'circle-radius': 8,
            'circle-stroke-color': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                '#000000',
                'rgba(0, 0, 0, 0)',
            ],
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                3,
                0,
            ],
            'circle-opacity': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0.1,
            ],
        },
    },

    pollutionPoint: {
        fill: {
            'circle-radius': 10,
            'circle-color': ['get', 'aqiColor'],
        },
        circle: {
            'circle-color': ['get', 'aqiColor'],
            'circle-radius': 10,
            'circle-stroke-color': '#000000',
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                3,
                1,
            ],
            'circle-opacity': 0.9,
        },
        circleDim: {
            'circle-color': ['get', 'aqiColor'],
            'circle-radius': 10,
            'circle-stroke-color': '#000000',
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                3,
                0,
            ],
            'circle-opacity': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0.1,
            ],
        },
    },
    pollutionText: {
        layout: {
            'text-font': ['League Mono Regular'],
            'text-field': ['get', 'aqi'],
            'text-allow-overlap': false,
            'text-size': 10,
            'symbol-sort-key': ['-', ['get', 'aqi']],
        },
        paint: {
            'text-color': '#000000',
            'text-halo-color': '#ffffff',
            'text-halo-width': 1.5,
        },
    },

    earthquakePoint: {
        circle: {
            'circle-color': [
                'case',
                ['>=', ['get', 'magnitude'], 8], '#a50f15',
                ['>=', ['get', 'magnitude'], 7], '#de2d26',
                ['>=', ['get', 'magnitude'], 6], '#fb6a4a',
                ['>=', ['get', 'magnitude'], 5], '#fc9272',
                ['>=', ['get', 'magnitude'], 4], '#fcbba1',
                '#fee5d9',
            ],
            'circle-radius': [
                'case',
                ['>=', ['get', 'magnitude'], 8], 21,
                ['>=', ['get', 'magnitude'], 7], 18,
                ['>=', ['get', 'magnitude'], 6], 15,
                ['>=', ['get', 'magnitude'], 5], 11,
                ['>=', ['get', 'magnitude'], 4], 8,
                ['>=', ['get', 'magnitude'], 3], 6,
                6,
            ],
            'circle-stroke-color': '#000000',
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                2,
                0,
            ],
            'circle-opacity': 0.9,
        },
        circleDim: {
            'circle-color': [
                'case',
                ['>=', ['get', 'magnitude'], 8], '#a50f15',
                ['>=', ['get', 'magnitude'], 7], '#de2d26',
                ['>=', ['get', 'magnitude'], 6], '#fb6a4a',
                ['>=', ['get', 'magnitude'], 5], '#fc9272',
                ['>=', ['get', 'magnitude'], 4], '#fcbba1',
                '#fee5d9',
            ],
            'circle-radius': [
                'case',
                ['>=', ['get', 'magnitude'], 8], 21,
                ['>=', ['get', 'magnitude'], 7], 18,
                ['>=', ['get', 'magnitude'], 6], 15,
                ['>=', ['get', 'magnitude'], 5], 11,
                ['>=', ['get', 'magnitude'], 4], 8,
                ['>=', ['get', 'magnitude'], 3], 6,
                6,
            ],
            'circle-stroke-color': '#000000',
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                2,
                0,
            ],
            'circle-opacity': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0.1,
            ],
        },
        fill: {
            'circle-radius': [
                'case',
                ['>=', ['get', 'magnitude'], 8], 21,
                ['>=', ['get', 'magnitude'], 7], 18,
                ['>=', ['get', 'magnitude'], 6], 15,
                ['>=', ['get', 'magnitude'], 5], 11,
                ['>=', ['get', 'magnitude'], 4], 8,
                ['>=', ['get', 'magnitude'], 3], 6,
                6,
            ],
            'circle-color': [
                'case',
                ['>=', ['get', 'magnitude'], 8], '#a50f15',
                ['>=', ['get', 'magnitude'], 7], '#de2d26',
                ['>=', ['get', 'magnitude'], 6], '#fb6a4a',
                ['>=', ['get', 'magnitude'], 5], '#fc9272',
                ['>=', ['get', 'magnitude'], 4], '#fcbba1',
                '#fee5d9',
            ],
            'circle-stroke-color': '#000000',
            'circle-stroke-width': ['case',
                ['boolean', ['feature-state', 'hover'], false],
                2,
                0,
            ],
        },
    },
    earthquakeText: {
        layout: {
            'text-font': ['League Mono Regular'],
            'text-field': ['get', 'magnitude'],
            'text-allow-overlap': false,
            'text-size': [
                'case',
                ['>=', ['get', 'magnitude'], 8], 12,
                ['>=', ['get', 'magnitude'], 7], 11,
                ['>=', ['get', 'magnitude'], 6], 10,
                ['>=', ['get', 'magnitude'], 5], 9,
                ['>=', ['get', 'magnitude'], 4], 8,
                7,
            ],
            // NOTE: should negate idk why
            'symbol-sort-key': ['-', ['get', 'magnitude']],
        },
        paint: {
            'text-color': '#000000',
            'text-halo-color': '#ffffff',
            'text-halo-width': 1.5,
        },
    },
    contactPoint: {
        circle: {
            'circle-color': '#1565c0',
            'circle-radius': 7,
            'circle-opacity': 1,
        },
        clusteredCircle: {
            'circle-color': '#1565c0',
            'circle-radius': [
                'interpolate',
                ['linear'],
                ['get', 'point_count'],
                5,
                10,
                15,
                25,
            ],
            'circle-opacity': 1,
        },
        clusterLabelLayout: {
            'text-field': '{point_count_abbreviated}',
            'text-size': 12,
        },
        clusterLabelPaint: {
            'text-color': '#ffffff',
        },
    },
};
