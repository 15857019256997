import AllActionTypes from './types.ts';

export const setNotificationId = data => ({
    type: AllActionTypes.SET_NOTIFICATION_ID,
    payload: data,
});

export const removeNotificationId = data => ({
    type: AllActionTypes.REMOVE_NOTIFICATION_ID,
    payload: data,
});
export const GET_VEHICLE_LIST_REQUEST = data => ({
    type: AllActionTypes.GET_VEHICLE_LIST_REQUEST,
    payload: data,
});
export const GET_VEHICLE_LIST_SUCCESS = data => ({
    type: AllActionTypes.GET_VEHICLE_LIST_SUCCESS,
    payload: data,
});
export const GET_VEHICLE_LIST_FAILURE = data => ({
    type: AllActionTypes.GET_VEHICLE_LIST_FAILURE,
    payload: null,
});
