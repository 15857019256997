/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './styles.scss';
import Red from './Red-fire.svg';
import Silver from './Silver-fire.svg';
import { returnTitleFromId } from '#utils/common';

const { REACT_APP_MAPBOX_ACCESS_TOKEN: TOKEN } = process.env;
if (TOKEN) {
    mapboxgl.accessToken = TOKEN;
}

const generateCircularCoordinates = (point, r) => {
    const allCorodinate = [];
    for (let i = 0; i <= 360; i += 10) {
        const x = point[0] + r * Math.cos((i * Math.PI) / 180);
        const y = point[1] + r * Math.sin((i * Math.PI) / 180);
        const coordinate = [x, y];
        allCorodinate.push(coordinate);
    }
    return allCorodinate;
};

const IncidentMap = (props) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(85.30014);
    const [lat, setLat] = useState(27.700769);
    const [zoom, setZoom] = useState(5);
    const { incidentTypes } = props;

    useEffect(() => {
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom,
        });
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    }, []);

    // eslint-disable-next-line max-len
    const generatePopup = (data, type) => new mapboxgl.Popup({ offset: 25, closeButton: false }).setHTML(
        `<div class='citizen-report-mapbox-popup'>
            <h3>
            ${returnTitleFromId(incidentTypes, data.hazard)}
            </h3>
            <div class='citizen-report-mapbox-popup-information'>
                <label> ${type === 'incident' ? 'Cause:' : 'Description:'}</label>  
                <div>${type === 'incident' ? data.cause : data.description} </div> 
            </div>
            <div class='citizen-report-mapbox-popup-information'>
                <label> ${type === 'incident' ? 'Title:' : 'Requested By:'}</label>  
                <div>
                ${type === 'incident' ? data.title : data.contactNumber} 
                </div> 
            </div>       
            <div class='citizen-report-mapbox-popup-information'>
                <label> ${type !== 'incident' ? 'Id:' : 'Id:'}</label>  
                <div>
                ${data.id} 
                </div> 
            </div>     
            `,
    );

    const plotPolygon = (data) => {
        if (map.current.getLayer('circlee')) {
            map.current.removeLayer('circlee');
        }
        if (map.current.getSource('circlee')) {
            map.current.removeSource('circlee');
        }
        map.current.addSource('circlee', {
            type: 'geojson',
            data: {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [data],
                },
            },
        });

        map.current.addLayer({
            id: 'circlee',
            type: 'fill',
            source: 'circlee',
            layout: {},
            paint: {
                'fill-color': 'red',
                'fill-opacity': 0.1,
            },
        });
    };

    useEffect(() => {
        const { mainData, nearbyIncidents } = props;
        if (mainData) {
            const { point } = mainData;
            const el = document.createElement('div');
            el.className = `${styles.markerMain}`;

            if (mainData && point && point.coordinates) {
                const polygonData = generateCircularCoordinates(point.coordinates, 0.005);

                setTimeout(() => {
                    new mapboxgl.Marker(el)
                        .setLngLat(point.coordinates)
                        .setPopup(generatePopup(mainData, 'citizen_report'))
                        .addTo(map.current);
                    plotPolygon(polygonData);
                }, 1000);

                map.current.flyTo({
                    center: [point.coordinates[0], point.coordinates[1]],
                    zoom: 14,
                });
            }
        }

        if (nearbyIncidents) {
            const featuresArray = nearbyIncidents.map(data => ({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: data.point.coordinates,
                },
                properties: {
                    ...data,
                },
            }));

            const nearbyCollection = {
                type: 'FeatureCollection',
                features: featuresArray,
            };

            nearbyCollection.features.forEach((marker) => {
                const el = document.createElement('div');
                el.className = `${styles.markerNearby}`;
                new mapboxgl.Marker(el)
                    .setLngLat(marker.geometry.coordinates)
                    .setPopup(generatePopup(marker.properties, 'incident'))
                    .addTo(map.current);
            });
        }
    }, [props]);

    return (
        <div>
            <div ref={mapContainer} className={styles.mapContainer} id="map" />
            <div className={styles.legendStyle}>
                <p className={styles.legendTitle}>Incident Legend</p>
                <div className={styles.legendMarkers}>
                    <div className={styles.legendWrapper}>
                        <div>
                            <img src={Red} alt="a" width="12px" height="12px" />
                        </div>

                        <div style={{ paddingLeft: '5px' }}>Citizen Report</div>
                    </div>
                    <div className={styles.legendWrapper}>
                        <div>
                            <img src={Silver} alt="a" width="12px" height="12px" />
                        </div>
                        <div style={{ paddingLeft: '5px' }}>Nearby Incidents</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IncidentMap;
