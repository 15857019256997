import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import useOutsideClick from './useOutsideClick';
import styles from './styles.scss';
import Icon from '#rscg/Icon';
import Asterisk from './Asterisk';

export const CustomSelect = ({
    input,
    selectedItem,
    options,
    meta,
    disabled,
    sup,
}) => {
    const divRef = useRef();
    const [toggle, handleToggle] = useOutsideClick(divRef);

    const handleSelection = (name) => {
        input.onChange(name);
    };

    return (
        <>
            <div
                className={`${styles.customSelect} ${toggle ? styles.showDropdown : ''}`}
                role="menuitem"
                tabIndex={-1}
                onKeyDown={() => {}}
                onClick={disabled === true ? () => {} : handleToggle}
                ref={divRef}
                onBlur={(e) => {
                    input.onBlur(e);
                }}
            >
                <p className={styles.selectedItem}>
                    <span className={styles.selectSpan}>
                        {selectedItem || input.value || 'Select an option'}
                    </span>
                    {!toggle && <Icon name="arrowDropdown" />}
                    {toggle && <Icon name="arrowDropup" />}
                </p>
                <ul className={styles.selectList}>
                    {options.length !== 0 ? (
                        options.map(item => (
                            <li
                                key={item.id}
                                role="presentation"
                                onKeyDown={() => {}}
                                onClick={() => handleSelection(item.title)}
                                {...input}
                            >
                                <span value={item.title}>{item.title}</span>
                            </li>
                        ))
                    ) : (
                        <li role="presentation" onKeyDown={() => {}} onClick={() => {}}>
                            Data not available!
                        </li>
                    )}
                </ul>
            </div>
            {meta.error && meta.touched && <span style={{ color: 'red' }}>{meta.error}</span>}
        </>
    );
};
const Select = ({ label, sup, ...props }) => (
    <div className={styles.selectWrapper}>
        <div className="displayInlineBlock">
            {label}
            {!!sup && <Asterisk />}
        </div>
        <CustomSelect {...props} />
    </div>
);

CustomSelect.defaultProps = {
    options: [],
    disabled: false,
    selectedItem: '',
};

CustomSelect.propTypes = {
    options: PropTypes.array,
    input: PropTypes.object,
    meta: PropTypes.object.isRequired,
    selectedItem: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

CustomSelect.defaultProps = {
    input: null,
    onChange: () => {},
    withSearch: false,
};

Select.defaultProps = {
    sup: '',
};
Select.propTypes = {
    label: PropTypes.string.isRequired,
    sup: PropTypes.string,
};

export default Select;
