/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getToken } from '../../../firebase';
import styles from './styles.scss';
import { setNotificationId } from '#store/atom/incident/actions';

const Toast = () => {
    const [notifications, setNotifications] = useState([]);
    const [firebaseMessage, setFirebaseMessage] = useState();
    const [currentNotification, setCurrentNotification] = useState();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);

    useEffect(() => {
        if (user && user.id) {
            getToken(user.id);
        }
    }, [user]);

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', (message) => {
            setFirebaseMessage(message);
        });
    }

    function removeItem(id) {
        setTimeout(() => {
            const newList = notifications && notifications.filter(item => item.id > id);
            setNotifications(newList);
        }, 10000);
    }

    useEffect(() => {
        if (firebaseMessage) {
            const { data } = firebaseMessage.data;
            console.log('firebase message received', data);
            const { id, label, vehicle, description, hazard } = data;
            if (currentNotification && currentNotification.id === id) {
                // console.log(`if entered`);
            } else {
                const notificationsList = notifications;
                notificationsList.push({ id, label, vehicle, description, hazard });
                setNotifications(notificationsList);
                setCurrentNotification(data);
                console.log('notificationsList', notificationsList);
                removeItem(id);
            }
        }
    }, [firebaseMessage]);

    const handelSeeMore = (id) => {
        dispatch(setNotificationId(id));
    };

    return (
        <>
            {notifications
                && notifications.map((item, index) => (
                    <div className={styles.right} style={{ top: `${index * 14}vh` }} key={item.id}>
                        <div className={styles.topLine} />
                        <div className={styles.toastBody}>
                            <div className={styles.bodyDivisionsStart}>
                                Emergency Vechicle Request Alert
                            </div>
                            <div className={styles.bodyDivisions}>{item.description}</div>
                            <div className={styles.bodyDivisions}>
                                <div>Label</div>
                                <div>Type of Incident</div>
                            </div>
                            <div className={styles.bodyDivisions}>
                                <div style={{ color: '#e35163' }}>{item.label}</div>
                                <div>{item.hazard}</div>
                            </div>
                            <div
                                className={styles.bodyDivisionsLast}
                                onClick={() => {
                                    handelSeeMore(item.id);
                                }}
                            >
                                See More
                            </div>
                        </div>
                    </div>
                ))}
        </>
    );
};

export default Toast;
